import React, { useState } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from "@react-oauth/google";

import CustomGoogleButton from '../components/CustomGoogleButton';

const Login = ({ handleLogin }) => {
   const [username, setUsername] = useState('');
   const [name, setName] = useState('');
   const [password, setPassword] = useState('');
   const [loginSession, setLoginSession] = useState('');
   
   const [errorMessage, setErrorMessage] = useState('');
   const [showLoginBox, setShowLoginBox] = useState(false);
   const [showSignUpBox, setShowSignUpBox] = useState(false);
   const [codeBoxStatus, setCodeBoxStatus] = useState("NONE");

   const handleLoginClick = async (e) => {
      console.log("hhhhh")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter email.');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            //formdata.append("file", files[0].file);
            formData.append("email", username);
            //   formData.append("password", password);
            //formData.append("code", password);
            //formData.append("session", "AYABeGsVFPg0CL151TBvijf41vsAHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMATGFybjphd3M6a21zOmFwLXNvdXRoLTE6NjU0NDM0NDQ0NzkwOmtleS8yNjg1NWU1NC05NTMzLTRhNDctYjYxNy1hYjgwYjMyNDkxZWQAuAECAQB4IiNWL_vNLuA_HYRb4PrTYxCHn3uVXc9cwgKEMGhCAIsB3uzmMqZEIMXBkL4D8AzRdwAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDEdNhttHOqm509PHlgIBEIA7HMi5gjYk0Jla8yGTfJaBzqmuM8_tUIwKAmLsMnuelGmbiXKfyLH7krV6iFMmn3cBlt-xA0xculTOgH0CAAAAAAwAABAAAAAAAAAAAAAAAAAAZ8q7MTX3Eb8tErMAgSwwU_____8AAAABAAAAAAAAAAAAAAABAAABLaXfDK5fc4JmIrGgHewLPBVLEcMyj6H1DaORrNOzXweaYkjS1TOWXLtk54ZtiyngpvWnOAg49W3ANKyDwOYKgerD30aijMGlj61cZXEqSBXq6mh5SHboalNvmwBeDNdivyv19HI0XP3RASlNtro5ccBY-6q8RqjyyQ2bB__tPfNO894_9ztP-hRvKtFC-Alc0WP4gk_Ep-S5L-5_YWwNsAPWFOzm7Jmb9JNFa_lRfGLplkz8GorMUVjExP9BGKwq6Ppbr7QmvX9Qc0b3qGtHv8L7aE2sdSt3YP9IhsUHY_5DSJpKqyJro18pukclCL7aO7EunkuF8ugD-rMWenBV8i7w73xFzamqjvUa6iT3jBHUlPLkRP4AqpmAzGQ93YxbWLSdqFHMFFYWzRSsLCLf0XMUmBza5nePrbB8NQRz");
           
            const headers = {
               "Content-Type": "application/json",
            }
            
           
             const response = await callAPI(apiUrls.COGNITOLOGIN, {}, "POST",formData,{},headers);
            // Here, you might perform validation before calling handleLogin
            // For simplicity, let's assume the login is successful immediately
            console.log(response);
            if (response.data.sentStatus === "SENT") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("SENT")
               setLoginSession(response.data.session)
               //localStorage.setItem("userId", response.data.userId)

               //const initial = (response?.data?.name?.charAt(0) ?? response?.data?.email?.charAt(0) ?? 'U').toUpperCase();
               //localStorage.setItem("initial", initial)
               //handleLogin(); // Call handleLogin function passed from parent component
               console.log("bbbb")
            } else if(response.data.status === "UNCONFIRMED") {
               console.log("UNCONF")
               setShowSignUpBox(true)
               setShowLoginBox(false)
                  setCodeBoxStatus("UNCONFIRMED")
                  setErrorMessage(response.data.message)
                 // setErrorMessage('Invalid username or password.');
               
            } else if(response.data.status === "NOTEXIST") {
               const msg = (
                  <>
                    User does not exist. Please <a href="javascript:void(0);" onClick={signupClick} style={{ color: "blue" }}>SignUp</a>
                  </>
                );
                setErrorMessage(msg);
            } else {
              
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };

   const handleSignUpClick = async (e) => {
      console.log("SignUp")
      setErrorMessage("")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter email');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
            formData.append("name", name);
       
            console.log("Username:", username);
            console.log("Password:", password);

            const headers = {
               "Content-Type": "application/json",
            }
            // const response = await callAPI(apiUrls.LOGIN, {}, "POST",formData,{},headers);
                const response = await callAPI(apiUrls.COGNITOREGISTER, {}, "POST",formData,{},headers);
            //    const response = await callAPI(apiUrls.COGNITOEMAILVERIFY, {}, "POST",formData,{},headers);

     //       const response = await callAPI(apiUrls.COGNITOVERIFY, {}, "POST", formData, {}, headers);
            // const response = await callAPI(apiUrls.COGNITOLOGIN, {}, "POST",formData,{},headers);
            // Here, you might perform validation before calling handleLogin
            // For simplicity, let's assume the login is successful immediately
            console.log(response);
            if (response.data.status === "REGISTERED") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("SENT")
            } else if(response.data.status === "UNCONFIRMED") {
               setCodeBoxStatus("UNCONFIRMED")
               setErrorMessage(response.data.message)
              // setErrorMessage('Invalid username or password.');
            } else {
               const msg = (
                  <>
                      
                      User already exists and email is verified. Please <a href="javascript:void(0);" onClick={loginClick} style={{ color: "blue" }}>Login</a>
                  </>
                );
                setErrorMessage(msg);
               //setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };
   const sentConfirmCode = async (e) => {
      console.log("ConfirmCode")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter email');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
                  const headers = {
               "Content-Type": "application/json",
            }
            
            const response = await callAPI(apiUrls.COGNITORESENDCODE, {}, "POST",formData,{},headers);
            console.log(response);
            if (response.data.sentStatus === "SENT") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("SENT")
            }  {
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };
   
   const handleLoginVerify = async (e) => {
      console.log("ConfirmCodeLogin")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter email');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
            formData.append("code", password);
            formData.append("session", loginSession);
                  const headers = {
               "Content-Type": "application/json",
            }
            const response = await callAPI(apiUrls.COGNITOVERIFY, {}, "POST",formData,{},headers);
            console.log(response);
            if (response.data.status === "LOGIN") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("VERIFIED")
                localStorage.setItem("userId", response.data.userId)
                localStorage.setItem("tokens", response.data.tokens)

               const initial = (response?.data?.name?.charAt(0) ?? response?.data?.email?.charAt(0) ?? 'U').toUpperCase();
               localStorage.setItem("initial", initial)
               handleLogin(); // Call handleLogin function passed from parent component
            }  else {
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };
   const verifyEmail = async (e) => {
      console.log("ConfirmCode")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter both email');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
            formData.append("code", password);
                  const headers = {
               "Content-Type": "application/json",
            }
            const response = await callAPI(apiUrls.COGNITOEMAILVERIFY, {}, "POST",formData,{},headers);
            console.log(response);
            if (response.data.sentStatus === "VERIFIED") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("VERIFIED")
            }  {
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };

   const loginClick = () => {
      setErrorMessage("")
      setShowLoginBox(true)
      setShowSignUpBox(false)
   }
   const signupClick = () => {
      setErrorMessage("")
      setShowSignUpBox(true)
      setShowLoginBox(false)
   }
   return (

      <div class="container">

         {(!showLoginBox && !showSignUpBox) &&
            <div className="headerWrap">
               <div className="header">
                  <div className="deckLogo">
                     {/* Logo or branding */}
                     <a href={window.location.origin} target="_blank">
                        <svg width="97" height="30" viewBox="0 0 216 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g clip-path="url(#clip0_1275_10371)">
                              <path d="M49.0598 10.33C46.2898 6.39996 42.6598 3.69996 38.1798 2.21996C35.5498 1.34996 32.2498 0.889956 28.2898 0.829956H0.759766V64.74H28.2898C37.9398 64.74 45.0798 60.77 49.7098 52.82C53.1198 46.92 54.8298 39.73 54.8298 31.23C54.8298 27.88 54.4198 24.35 53.6198 20.65C52.8098 16.95 51.2898 13.51 49.0698 10.33H49.0598ZM39.2198 44.32C36.7298 50.53 32.3398 53.64 26.0398 53.64H13.7298V11.93H26.0398C32.1098 11.93 36.1698 13.66 38.2198 17.13C40.2398 20.63 41.2498 25.63 41.2498 32.13C41.2498 36.84 40.5698 40.9 39.2098 44.31L39.2198 44.32Z" fill="white" />
                              <path d="M98.7397 25.41C96.6897 22.23 94.0897 19.9 90.9397 18.43C87.7897 16.96 84.2497 16.22 80.3197 16.22C73.6997 16.22 68.3197 18.4 64.1697 22.77C60.0197 27.13 57.9497 33.41 57.9497 41.59C57.9497 50.32 60.2497 56.62 64.8397 60.49C69.4397 64.36 74.7397 66.3 80.7497 66.3C88.0297 66.3 93.6997 64 97.7497 59.41C100.35 56.52 101.81 53.67 102.13 50.87H89.5097C88.8397 52.26 88.0797 53.34 87.2097 54.12C85.6197 55.57 83.5497 56.29 81.0097 56.29C78.6097 56.29 76.5597 55.7 74.8497 54.51C72.0497 52.6 70.5597 49.28 70.3797 44.54H102.72C102.78 40.46 102.65 37.34 102.33 35.18C101.78 31.48 100.58 28.23 98.7297 25.42L98.7397 25.41ZM70.6897 36.38C71.0897 33.32 72.0797 30.89 73.6597 29.1C75.2297 27.31 77.4497 26.41 80.3197 26.41C82.9497 26.41 85.1497 27.26 86.9297 28.95C88.7097 30.64 89.6997 33.12 89.8997 36.39H70.6897V36.38Z" fill="white" />
                              <path d="M128.82 26.8C131.74 26.8 133.89 27.75 135.28 29.66C136.23 31.08 136.83 32.67 137.06 34.43H149.72C149.03 27.7 146.79 23 143.02 20.34C139.25 17.68 134.4 16.35 128.47 16.35C121.5 16.35 116.04 18.6 112.08 23.11C108.12 27.62 106.14 33.93 106.14 42.06C106.14 49.26 107.93 55.11 111.52 59.62C115.1 64.13 120.7 66.38 128.3 66.38C135.9 66.38 141.64 63.68 145.51 58.27C147.94 54.92 149.3 51.36 149.59 47.6H136.97C136.71 50.09 135.97 52.11 134.76 53.67C133.55 55.23 131.49 56.01 128.6 56.01C124.52 56.01 121.75 54.04 120.28 50.11C119.47 48 119.07 45.21 119.07 41.74C119.07 38.1 119.47 35.18 120.28 32.98C121.81 28.85 124.66 26.78 128.82 26.78V26.8Z" fill="white" />
                              <path d="M180.41 35.7799L196.93 17.6999H181.62L166.23 35.4699V1.04993H154.09V64.7399H166.23V49.8699L171.35 44.5299L182.67 64.7399H197.58L180.41 35.7799Z" fill="white" />
                              <path d="M198.15 2.02995H200.94V9.37995H202.32V2.02995H205.11V0.829956H198.15V2.02995Z" fill="white" />
                              <path d="M213.64 0.829956L211.67 5.98995C211.52 6.41995 211.39 6.80995 211.27 7.17995C211.19 7.42995 211.11 7.69995 211.03 7.95995V7.93996C210.99 7.80996 210.95 7.67996 210.91 7.54996C210.82 7.24996 210.73 6.97996 210.64 6.72996C210.55 6.47996 210.47 6.22995 210.37 5.98995L208.37 0.839951H206.48V9.37995H207.75V4.67995C207.75 4.33995 207.75 4.00995 207.75 3.66995C207.75 3.32995 207.74 2.98995 207.72 2.63995C207.72 2.47995 207.72 2.32996 207.7 2.15996C207.7 2.14996 207.7 2.13995 207.7 2.12995C207.7 2.14995 207.71 2.17995 207.72 2.19995C207.78 2.37995 207.84 2.54996 207.9 2.72996C207.98 2.95996 208.05 3.18996 208.13 3.39996C208.2 3.60996 208.28 3.81996 208.36 4.03996C208.44 4.25996 208.52 4.46996 208.6 4.68996L210.43 9.38995H211.64L213.45 4.68996C213.57 4.35996 213.68 4.03996 213.79 3.72996C213.9 3.41996 214.01 3.09995 214.12 2.77995C214.19 2.57995 214.26 2.34995 214.33 2.12995C214.32 2.50995 214.31 2.87996 214.3 3.22996C214.29 3.69996 214.28 4.17996 214.27 4.68996V9.38995H215.54V0.849953H213.65L213.64 0.829956Z" fill="white" />
                           </g>
                           <defs>
                              <clipPath id="clip0_1275_10371">
                                 <rect width="216" height="67" fill="white" />
                              </clipPath>
                           </defs>
                        </svg>
                     </a>
                  </div>
                  <div className="rSec">
                     <button
                        href="javascript:void(0);"
                        className="btn PrimaryOutline"
                        onClick={() => {
                           signupClick();
                        }}
                     >
                        SignUp
                     </button>
                     <button
                        href="javascript:void(0);"
                        className="btn PrimaryOutline"
                        onClick={() => {
                           loginClick();
                        }}

                     >
                        Login
                     </button>
                  </div>
               </div>
            </div>
         }
         <div class="wrapper flex alignCenter">
            <div class="loginSection" style={{ minWidth: '380px' }}>
               <div class="headDeck">
                  <div class="logo">
                     <div class="logoImg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="158" height="53" viewBox="0 0 158 53" fill="none">
                           <path d="M23.1627 51.668H0.5V0H23.1627C26.4223 0.0467372 29.1347 0.420635 31.2998 1.12169C34.9877 2.31349 37.9737 4.49846 40.2579 7.67659C42.0899 10.2471 43.339 13.028 44.0052 16.0192C44.6714 19.0104 45.0045 21.8613 45.0045 24.5721C45.0045 31.4425 43.6007 37.2612 40.7932 42.0284C36.9863 48.4548 31.1095 51.668 23.1627 51.668ZM31.3355 13.1799C29.6462 10.3757 26.3033 8.97355 21.3068 8.97355H11.1711V42.6944H21.3068C26.4937 42.6944 30.1102 40.1823 32.1564 35.1581C33.2747 32.4006 33.8338 29.1173 33.8338 25.3082C33.8338 20.0503 33.001 16.0075 31.3355 13.1799Z" fill="#D9D9D9" />
                           <path d="M81.7773 40.4511C81.5156 42.7178 80.3141 45.0196 78.1727 47.3565C74.8417 51.0721 70.1783 52.9299 64.1825 52.9299C59.2336 52.9299 54.8676 51.3642 51.0846 48.2328C47.3015 45.1014 45.41 40.0071 45.41 32.9497C45.41 26.3364 47.1111 21.2654 50.5135 17.7368C53.9397 14.2081 58.3771 12.4438 63.8256 12.4438C67.0615 12.4438 69.9761 13.0397 72.5695 14.2315C75.1629 15.4233 77.3043 17.3045 78.9936 19.875C80.5163 22.1418 81.5037 24.7707 81.9558 27.7619C82.2175 29.5145 82.3246 32.0384 82.277 35.3333H55.6528C55.7955 39.1658 57.0209 41.8532 59.3288 43.3955C60.7326 44.3536 62.4218 44.8327 64.3967 44.8327C66.4904 44.8327 68.1916 44.2485 69.5002 43.08C70.214 42.4491 70.8445 41.5728 71.3918 40.4511H81.7773ZM71.713 28.7434C71.5464 26.1027 70.7256 24.1047 69.2504 22.7493C67.799 21.3706 65.9908 20.6812 63.8256 20.6812C61.4701 20.6812 59.6381 21.4056 58.3295 22.8545C57.0447 24.3034 56.2357 26.2663 55.9026 28.7434H71.713Z" fill="#D9D9D9" />
                           <path d="M118.8 27.166H108.379C108.188 25.7405 107.701 24.4552 106.915 23.3102C105.773 21.7679 104.001 20.9967 101.598 20.9967C98.1716 20.9967 95.828 22.6675 94.5669 26.0093C93.9007 27.7853 93.5676 30.1455 93.5676 33.0899C93.5676 35.8942 93.9007 38.1493 94.5669 39.8552C95.7804 43.0333 98.0645 44.6224 101.419 44.6224C103.799 44.6224 105.488 43.9914 106.487 42.7295C107.486 41.4676 108.093 39.8318 108.307 37.8221H118.693C118.455 40.86 117.337 43.7343 115.338 46.4451C112.15 50.815 107.427 53 101.169 53C94.9119 53 90.308 51.1772 87.3577 47.5317C84.4074 43.8862 82.9322 39.1541 82.9322 33.3353C82.9322 26.7687 84.562 21.6627 87.8217 18.0172C91.0813 14.3717 95.5781 12.5489 101.312 12.5489C106.19 12.5489 110.175 13.6239 113.268 15.7738C116.385 17.9237 118.229 21.7211 118.8 27.166Z" fill="#D9D9D9" />
                           <path d="M157.5 51.668H145.223L135.908 35.3333L131.697 39.6448V51.668H121.704V0.175265H131.697V28.0073L144.366 13.6356H156.965L143.367 28.2526L157.5 51.668Z" fill="#D9D9D9" />
                        </svg>
                     </div>
                     <div class="betaTag">
                        BETA
                     </div>
                  </div>
               </div>
               {(showLoginBox || showSignUpBox) && <>
                  {showLoginBox ? (
                     <>
                        <div class="headTxt mt-24">
                           <h3>Sign in</h3>
                           <p class="mt-8">You’re here because you requested<br />access to Deck.</p>
                        </div>
                        
                           {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                           <div class="dForm dbBox mt-12 gLoginForm">
                              <div class="row gap24 flexColum w100">
                              {codeBoxStatus === "SENT" ? (
                                     <div class="col flex flexColum w100">
                                     <label class="mb-8">Code</label>
                                     <input type="password" name="" placeholder="password" class="w100" id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required />
 
                                  </div>
                                 ): (
                                   
                                    <div class="col flex flexColum w100">
                                       <label class="mb-8">Email</label>
                                       <input type="text" name="" placeholder="Email" class="w100" id="username"
                                          value={username}
                                          onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                 )}
                              </div>
                              <div class="actionButtons jcflxStart w100">
                                 { codeBoxStatus === "SENT" ? (
                                    <button class="btn PrimaryOutline" type="submit"  onClick={handleLoginVerify}>Verify</button>   
                                 ) : (   
                                 <button class="btn PrimaryOutline" type="submit"  onClick={handleLoginClick}>Log In</button>
                                 )}  
                              </div>
                           </div>
                        
                     </>
                  ) : (
                     <>
                        <div class="headTxt mt-24">
                           <h3>Sign Up</h3>
                        </div>
                  
                           {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                           <div class="dForm dbBox mt-12 gLoginForm">
                              {codeBoxStatus === "VERIFIED" ? (
                                 <div class="row gap24 flexColum w100">
                                 <div class="col flex flexColum w100">
                                    Your Email is verifed Please <a  href="javascript:void(0);"  onClick={loginClick} style={{color:"blue"}}> login</a>
                                 </div>
                              </div>
                               ) :  (

                                 <>
                                 <div class="row gap24 flexColum w100">
                                 {codeBoxStatus === "SENT" ? (
                                     <div class="col flex flexColum w100">
                                     <label class="mb-8">Code</label>
                                     <input type="password" name="" placeholder="password" class="w100" id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required />
 
                                  </div>
                                 ): (
                                   <>
                                    <div class="col flex flexColum w100">
                                       <label class="mb-8">Email</label>
                                       <input type="text" name="" placeholder="Email" class="w100" id="username"
                                          value={username}
                                          onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                    {(codeBoxStatus !== "UNCONFIRMED" && codeBoxStatus !== "SENT") && <div class="col flex flexColum w100">
                                          <label class="mb-8">Name</label>
                                          <input type="text" name="" placeholder="Name" class="w100" id="name"
                                             value={name}
                                             onChange={(e) => setName(e.target.value)} />
                                       </div>
                                 }
                                       </>
                                  
                                 )}

                                 </div>

                                 
                                 
                                 <div class="actionButtons jcflxStart w100">
                                    {codeBoxStatus === "UNCONFIRMED"  ? ( 
                                    <button class="btn PrimaryOutline" type="submit"  onClick={sentConfirmCode} >Confirm</button>
                                    ): codeBoxStatus === "SENT" ? (
                                       <button class="btn PrimaryOutline" type="submit"  onClick={verifyEmail} >Verify</button>
                                    ) :(
                                       <button class="btn PrimaryOutline" type="submit"  onClick={handleSignUpClick} >SignUp</button>
                                    )}
                                 </div>
                                 </>
                               )}
                              
                           </div>
                        
                     </>
                  )}

                  <GoogleOAuthProvider clientId="1039487477793-8iv47jsvilbe8ug57q9503o79u55a4pv.apps.googleusercontent.com" scope="https://www.googleapis.com/auth/presentations">
                     <CustomGoogleButton handleLogin={handleLogin} />
                  </GoogleOAuthProvider>
               </>
               }

            </div>
         </div>
      </div>
   );
};

export default Login;
